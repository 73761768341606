<div class="medical-record">
  <p-card>
    <div class="bold" class="header d-flex justify-content-between">
      <label class="font-size-18">{{ "Voice Analyst" | translate }}</label>
      <button
        icon="fas fa-plus"
        class="p-button-raised p-button-success"
        pButton
        [label]="'StartStreamingSession' | translate"
        (click)="startStreamingSession()"
      ></button>
    </div>
    <div class="content">
      <app-voice-analyst-timeline
        [items]="sessions"
        (deleteItem)="removeItemClick($event)"
        *ngIf="sessions"
      ></app-voice-analyst-timeline>
    </div>
  </p-card>
</div>

<p-dialog
  appendTo="body"
  *ngIf="showStreamingPopUp"
  [header]="'NewExaminationSession' | translate"
  [(visible)]="showStreamingPopUp"
  [modal]="true"
  [style]="{ 'min-width': '70vw', 'max-width': '100%', 'min-height': '70vh', height: 'auto' }"
  [contentStyle]="{ overflow: 'visible' }"
  (visibleChange)="destroyActiveSession()"
>
  <div class="pb-2">
    <div class="pb-2">
      {{ "The session will stay active as long as this pop-up is open or 10 minutes pass:" | translate }}
    </div>
    <p-progressBar [value]="progressBarValue" [showValue]="false"></p-progressBar>
    <div class="pt-2 pb-2">
      {{ "Please continue with the procedure on the Voice Analyst application." | translate }}
    </div>
  </div>
  <div class="d-flex">
    <div class="w-100 d-flex flex-column">
      <div style="height: 42%">
        <canvas id="chart-volume"></canvas>
      </div>
      <div class="p-5"></div>
      <div style="height: 42%">
        <canvas id="chart-pitch"></canvas>
      </div>
    </div>
    <table class="table h-75 ml-4 mt-4" *ngIf="summary">
      <tr>
        <th scope="col">{{ "minimumPitch" | translate }}</th>
        <td>{{ summary.minimumPitch | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "averagePitch" | translate }}</th>
        <td>{{ summary.averagePitch | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "maximumPitch" | translate }}</th>
        <td>{{ summary.maximumPitch | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "minimumVolume" | translate }}</th>
        <td>{{ summary.minimumVolume | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "averageVolume" | translate }}</th>
        <td>{{ summary.averageVolume | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "maximumVolume" | translate }}</th>
        <td>{{ summary.maximumVolume | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "recordingTime" | translate }}</th>
        <td>{{ summary.recordingTime | number : "1.0-0" }}</td>
      </tr>
    </table>
  </div>
</p-dialog>
