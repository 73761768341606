<header [@slideInOut]="isOpen" class="clear" *ngIf="isHeaderVisible">
  <div class="container-fluid">
    <div class="row header--container align-items-center">
      <div>
        <a (click)="toggleMenu()" class="sidebar-toggler"><i class="fas fa-bars"></i></a>
        <span class="p-1"></span>
        <span style="color: white"
          >{{ "You are logged in as " | translate }} {{ "registrationPage." + userRole | translate }}</span
        >
      </div>

      <div>
        <div class="right-menu">
          <div class="d-inline-block relative">
            <button class="btn btn-link header-btn" #assistantIcon>
              <svg
                height="17pt"
                style="color: var(--header-font-color)"
                width="17pt"
                viewBox="-33 0 400 400.30357"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  style="fill: var(--header-font-color)"
                  d="m334.4375 189.464844c0-21.777344-9.746094-41.285156-25.058594-54.515625-3.863281-74.789063-66.589844-134.949219-142.132812-134.949219-75.453125 0-138.179688 60.164062-142.042969 134.957031-15.308594 13.226563-25.050781 32.734375-25.050781 54.507813 0 39.757812 32.339844 72.097656 72.089844 72.097656 5.046874 0 9.140624-4.089844 9.140624-9.144531l.003907-125.898438c0-2.429687-.960938-4.75-2.675781-6.464843-1.714844-1.714844-4.042969-2.679688-6.464844-2.679688-9.703125 0-18.949219 1.953125-27.40625 5.441406 9.476562-58.964844 61.117187-104.53125 122.5-104.53125 61.296875 0 112.929687 45.566406 122.40625 104.53125-8.457032-3.488281-17.699219-5.441406-27.398438-5.441406-5.046875 0-9.140625 4.089844-9.140625 9.144531v125.898438c0 5.054687 4.09375 9.144531 9.140625 9.144531 9.714844 0 18.976563-1.957031 27.441406-5.457031-7.722656 48.390625-43.835937 88.457031-91.816406 100.621093-5.507812-10.253906-17.15625-17.40625-30.722656-17.40625-18.839844 0-34.164062 13.679688-34.164062 30.492188s15.324218 30.492188 34.164062 30.492188c16.917969 0 30.902344-11.0625 33.59375-25.496094 60.785156-14.652344 105.382812-68.199219 108.570312-130.851563 15.292969-13.234375 25.023438-32.730469 25.023438-54.492187zm-271.339844 53.035156c-25.324218-4.355469-44.660156-26.480469-44.660156-53.035156 0-26.554688 19.335938-48.671875 44.664062-53.027344zm104.152344 139.519531c-8.753906 0-15.878906-5.476562-15.878906-12.207031s7.125-12.207031 15.878906-12.207031 15.875 5.476562 15.875 12.207031-7.121094 12.207031-15.875 12.207031zm104.242188-245.582031c25.324218 4.355469 44.660156 26.472656 44.660156 53.027344 0 26.554687-19.335938 48.679687-44.660156 53.035156zm0 0"
                />
              </svg>
            </button>
            <div class="assistant-popup iti-popup" *ngIf="isAssistantPopupVisible" #assistantContainer>
              <h3 class="text-center popup-header uppercase">
                {{ "HECA" | translate }}
              </h3>
              <div *ngIf="assistantHistory.length === 0" style="margin-left: 1rem; margin-bottom: 1rem; color: #888">
                {{ "assistant.placeholder" | translate }}
              </div>
              <ul
                #scrollToBottom
                [scrollTop]="scrollToBottom.scrollHeight"
                class="contain-scrolling"
                *ngIf="assistantHistory"
              >
                <ng-container *ngFor="let command of assistantHistory; let i = index">
                  <li
                    [class.is-answer]="command.isAnswer"
                    [class.first-continuous-answer]="command.firstContinuousAnswer"
                  >
                    <p [innerHTML]="command.text"></p>
                  </li>
                </ng-container>
              </ul>
              <div class="toolbar text-center" style="display: flex">
                <button
                  style="border-radius: 40px; margin: 0.5rem"
                  [style.backgroundColor]="getMicColor()"
                  [disabled]="disableSpeechControls"
                  class="btn btn-link"
                  (click)="toggleRecording()"
                >
                  <i class="fas fa-microphone"></i>
                </button>
                <button
                  #iconButton2
                  [ngStyle]="{ display: textToSpeechEnabled ? 'none' : 'block' }"
                  style="border-radius: 40px; margin: 0.5rem"
                  class="btn btn-link"
                  (click)="toggleTextToSpeech()"
                >
                  <i class="fas fa-volume-mute"></i>
                </button>
                <button
                  #iconButton
                  [ngStyle]="{ display: !textToSpeechEnabled ? 'none' : 'block' }"
                  style="border-radius: 40px; margin: 0.5rem"
                  class="btn btn-link"
                  (click)="toggleTextToSpeech()"
                >
                  <i class="fas fa-volume-up"></i>
                </button>
                <input
                  #speechInput
                  type="text"
                  pInputText
                  [(ngModel)]="command"
                  style="margin: 0.5rem !important"
                  [disabled]="disableSpeechControls"
                  (keyup.ArrowUp)="
                    command = assistantHistory.length > 1 ? assistantHistory[assistantHistory.length - 2].text : ''
                  "
                  (keyup.enter)="executeCommand()"
                  (blur)="onLoseFocus()"
                />
              </div>
            </div>
          </div>

          <div class="d-inline-block relative" style="margin-right: 25px">
            <button
              class="btn btn-link header-btn"
              [class.custom-badge]="notificationsCount > 0"
              [attr.data-count]="notificationsCount"
              [pTooltip]="notificationsCount === 0 ? ('headerPage.noNotificationsTooltip' | translate) : null"
              tooltipPosition="bottom"
              #notificationsIcon
            >
              <i class="fas fa-bell"></i>
            </button>
            <div class="notification-list-popup iti-popup" *ngIf="isNotificationPopupVisible" #notificationsContainer>
              <h3 class="text-center popup-header uppercase">
                {{ "headerPage.Notifications" | translate }}
              </h3>
              <ul class="contain-scrolling">
                <li
                  *ngFor="let notification of notifications; let i = index"
                  [ngClass]="[i % 2 == 1 ? 'odd' : 'even']"
                  class="pointer"
                  (click)="markNotificationAsRead(notification.id, notification.type, notification)"
                  [ngStyle]="{ background: notification.isCritical === true ? '#ffe8e8' : '#ffffff' }"
                >
                  <div class="notification-header">
                    <i
                      >{{ notification.Patient ? notification.Patient.fullname : "" }}
                      <span *ngIf="notification.isCritical === true" style="font-weight: normal">
                        - {{ "headerPage.criticalEvent" | translate }}</span
                      ></i
                    >
                  </div>
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      {{ notification.Message | translate }}
                    </div>
                  </div>
                  <ng-container *ngIf="notification.NotificationType === 'ADoctorRequestedASecondOpinion'">
                    <div>
                      <label class="bold">{{ "Message" | translate }}:</label> {{ notification.data.notes }}
                    </div>
                    <div>
                      <label class="bold">{{ "Patient" | translate }}</label
                      >: {{ notification.data.patientName }}
                    </div>
                  </ng-container>
                  <div class="notification-footer">
                    <small>{{ notification.createdStr }}</small>
                  </div>
                </li>
              </ul>
              <div class="toolbar text-center">
                <button
                  *ngIf="notificationsCount < 6"
                  class="pointer notification-anchor"
                  (click)="navigateToNotifications()"
                >
                  {{ "headerPage.goToNotifications" | translate }}
                </button>
                <button
                  *ngIf="notificationsCount > 5"
                  class="pointer notification-anchor"
                  (click)="navigateToNotifications()"
                >
                  {{ "headerPage.goToNotifications" | translate }} (+{{ notificationsCount - 5 }}
                  {{ "headerPage.Notifications" | translate }})
                </button>
              </div>
            </div>
          </div>
          <div class="d-inline-block relative">
            <div ngbDropdown placement="bottom-right" class="d-inline-block">
              <button
                class="btn btn-link"
                id="dropdownBasic3"
                ngbDropdownToggle
                style="
                  padding-left: 25px;
                  padding-right: 25px;
                  width: auto !important;
                  background: #2d0c1229;
                  color: #fff !important;
                "
              >
                <span style="color: #fff !important; display: inline-block; padding-right: 5px">{{
                  getLanguageName()
                }}</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic4">
                <button ngbDropdownItem (click)="changeLanguage('en')">
                  {{ "general.language_en" | translate }}
                </button>
                <button ngbDropdownItem (click)="changeLanguage('es')">
                  {{ "general.language_es" | translate }}
                </button>
                <button ngbDropdownItem (click)="changeLanguage('pt')">
                  {{ "general.language_pt" | translate }}
                </button>
                <!--
                <button ngbDropdownItem (click)="changeLanguage('sv')">
                  {{ "general.language_sv" | translate }}
                </button>
                <button ngbDropdownItem (click)="changeLanguage('it')">
                  {{ "general.language_it" | translate }}
                </button>
                <button ngbDropdownItem (click)="changeLanguage('tr')">
                  {{ "general.language_tr" | translate }}
                </button> -->
              </div>
            </div>
          </div>
          <div class="d-inline-block relative">
            <button
              style="border-radius: 40px; margin: 0.5rem; background: var(--header-button-background)"
              [disabled]="disableSpeechControls"
              class="p-button-default"
              (click)="logoutUser()"
            >
              <i class="fas fa-power-off"></i>
            </button>
          </div>
          <!-- <div class="d-inline-block relative">
            <div ngbDropdown placement="bottom-right" class="d-inline-block">
              <button class="btn btn-link header-btn" id="dropdownBasic2" ngbDropdownToggle>
                <i class="fas fa-user fa-fw"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <div class="border-bottom text-center mb-1 pb-1">{{ username }}</div>
                <button ngbDropdownItem [routerLink]="'/account'">
                  <i class="fas fa-user fa-fw"></i> {{ "general.account" | translate }}
                </button>
                <button ngbDropdownItem (click)="logoutUser()">
                  <i class="fas fa-lock fa-fw"></i> {{ "general.logout" | translate }}
                </button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</header>
