import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Helpers } from "src/app/services/app.helpers";

@Component({
  selector: "app-add-note",
  templateUrl: "./add-note.component.html",
  styleUrls: ["./add-note.component.scss"],
})
export class AddNoteComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;

  constructor(private fb: FormBuilder, private translationService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["model"]) this.createForm();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      data: [(this.model && this.model.data) || "", Validators.required],
      date: [(this.model && moment(this.model.date).toDate()) || "", Validators.required],
    });
  }

  onClose() {
    this.closed.emit();
  }

  save() {
    Helpers.markFormGroupTouched(this.form);
    Helpers.markFormGroupDirty(this.form);

    if (!this.form.valid) {
      return;
    }

    const model = this.form.getRawValue();

    if (this.model) {
      model.id = this.model.id;
    }
    this.success.emit(model);
    this.form.reset();
    this.closed.emit();
  }
}
