import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StreamingExaminationsComponent } from "./streaming-examinations.component";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { CardModule } from "primeng/card";
import { ChartModule } from "primeng/chart";
import { VoiceAnalystTimelineComponent } from "./voice-analyst-timeline/voice-analyst-timeline.component";
import { ProgressBarModule } from "primeng/progressbar";

@NgModule({
  declarations: [StreamingExaminationsComponent, VoiceAnalystTimelineComponent],
  imports: [CommonModule, TranslateModule, DialogModule, ButtonModule, CardModule, ChartModule, ProgressBarModule],
  exports: [StreamingExaminationsComponent],
})
export class StreamingExaminationsModule {}
