<div class="card">
  <!-- <div class="card-header">
    <div class="flex-grow-1 title">This is the title</div>
  </div> -->
  <div class="card-body flex-grow-1" style="padding: 10px">
    <div class="iti-timeline-container">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="timeline">
            <div *ngIf="!items || items.length === 0" class="center-text">
              * {{ "No records found for the patient" | translate }}
            </div>
            <div class="item-container">
              <div class="iti-timeline-item" *ngFor="let item of items">
                <ng-container>
                  <div class="dot"></div>
                  <div class="arrow"></div>
                  <div class="item-content">
                    <div>
                      <div class="header">
                        {{ formatDate(item.createdAt, "DD-MM-YYYY, HH:mm") }}
                        <div class="read-icon">
                          <a (click)="removeItemClick(item)" class="pointer pr-1"
                            ><i class="fas fa-times" style="color: #ef5a40"></i
                          ></a>
                          <a (click)="showGraphClick(item)" class="pointer"
                            ><i class="fas fa-eye" style="color: #6cdd02"></i
                          ></a>
                        </div>
                      </div>
                      <div class="" style="white-space: pre-line; max-height: 550px; max-width: 500px">
                        <div>{{ "recordingTime" | translate }}: {{ item.recordingTime }}</div>
                        <div>{{ "minimumPitch" | translate }}: {{ item.minimumPitch }}</div>
                        <div>{{ "averagePitch" | translate }}: {{ item.averagePitch }}</div>
                        <div>{{ "maximumPitch" | translate }}: {{ item.maximumPitch }}</div>
                        <div>{{ "minimumVolume" | translate }}: {{ item.minimumVolume }}</div>
                        <div>{{ "averageVolume" | translate }}: {{ item.averageVolume }}</div>
                        <div>{{ "maximumVolume" | translate }}: {{ item.maximumVolume }}</div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  appendTo="body"
  *ngIf="showGraph"
  [header]="'SessionData' | translate"
  [(visible)]="showGraph"
  [modal]="true"
  [style]="{ 'min-width': '70vw', 'max-width': '100%', 'min-height': '20vh', height: 'auto' }"
  [contentStyle]="{ overflow: 'visible' }"
  (visibleChange)="showGraph = false; selectedItem = null"
>
  <button
    icon="fas fa-minus"
    class="p-button-raised p-button-success"
    pButton
    [label]="'Close' | translate"
    (click)="showGraph = false; selectedItem = null"
  ></button>
  <div class="d-flex">
    <div class="w-100 d-flex flex-column">
      <p-chart
        type="line"
        [data]="volumeDataset"
        [options]="volumeOptionsLineChart"
        height="30vh"
        width="40vw"
      ></p-chart>
      <div class="p-2"></div>
      <p-chart
        type="scatter"
        [data]="pitchDataset"
        [options]="pitchOptionsLineChart"
        height="30vh"
        width="40vw"
      ></p-chart>
    </div>
    <table class="table h-75 ml-4 mt-4" *ngIf="selectedItem">
      <tr>
        <th scope="col">{{ "minimumPitch" | translate }}</th>
        <td>{{ selectedItem.minimumPitch | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "averagePitch" | translate }}</th>
        <td>{{ selectedItem.averagePitch | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "maximumPitch" | translate }}</th>
        <td>{{ selectedItem.maximumPitch | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "minimumVolume" | translate }}</th>
        <td>{{ selectedItem.minimumVolume | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "averageVolume" | translate }}</th>
        <td>{{ selectedItem.averageVolume | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "maximumVolume" | translate }}</th>
        <td>{{ selectedItem.maximumVolume | number : "1.0-0" }}</td>
      </tr>
      <tr>
        <th scope="col">{{ "recordingTime" | translate }}</th>
        <td>{{ selectedItem.recordingTime | number : "1.0-0" }}</td>
      </tr>
    </table>
  </div>
</p-dialog>
