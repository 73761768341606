<form [formGroup]="form" (ngSubmit)="save()">
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "Note" | translate }}:</label>
    </div>
    <div class="p-col-2">
      <textarea rows="10" pInputTextarea formControlName="data"></textarea>
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "Date" | translate }}:</label>
    </div>
    <div class="p-col">
      <p-calendar formControlName="date" yearNavigator="true" monthNavigator="true"> </p-calendar>
    </div>
  </div>
  <br />
  <div>
    <button
      type="submit"
      class="p-button-rounded"
      icon="fas fa-check"
      [label]="'general.button.texts.save' | translate"
      pButton
    ></button>
    <span class="m-2"></span>
  </div>
</form>
