import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { GarrySession } from "../streaming-examinations.model";
import { ChartOptions } from "chart.js";

@Component({
  selector: "app-voice-analyst-timeline",
  templateUrl: "./voice-analyst-timeline.component.html",
  styleUrls: ["./voice-analyst-timeline.component.scss"],
})
export class VoiceAnalystTimelineComponent implements OnInit {
  selectedItem!: GarrySession;
  showGraph = false;
  momentUnits = {
    year: "years" as moment.unitOfTime.DurationConstructor,
    week: "weeks" as moment.unitOfTime.DurationConstructor,
    today: "days" as moment.unitOfTime.DurationConstructor,
  };
  volumeOptionsLineChart: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: `${this.translationService.instant("Time") as string} (sec)`,
        },
      },
      y: {
        title: {
          display: true,
          text: `${this.translationService.instant("Volume") as string} (dB)`,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return `${context.formattedValue} dB`;
          },
          title: (context: any) => {
            return `${context[0].label} sec`;
          },
        },
      },
    },
  };
  pitchOptionsLineChart: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: `${this.translationService.instant("Time") as string} (sec)`,
        },
      },
      y: {
        title: {
          display: true,
          text: `${this.translationService.instant("Pitch") as string} (Hz)`,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return `${context.raw} Hz`;
          },
          title: (context: any) => {
            return `${context[0].label} sec`;
          },
        },
      },
    },
  };
  pitchDataset: any;
  volumeDataset: any;

  @Input() items!: GarrySession[];
  @Output() deleteItem = new EventEmitter<GarrySession>();

  constructor(private translationService: TranslateService) {}

  ngOnInit() {}

  createDateSets() {
    this.pitchDataset = {
      labels: [],
      datasets: [{ data: [], fill: false, label: this.translationService.instant("Pitch") }],
    };
    this.volumeDataset = {
      labels: [],
      datasets: [
        {
          backgroundColor: "#6cdd02",
          borderColor: "#6cdd02",
          pointRadius: 0,
          data: [],
          fill: false,
          label: this.translationService.instant("Volume"),
        },
      ],
    };

    this.selectedItem.sessionData.forEach((x) => {
      this.volumeDataset.labels.push(x.time?.toString());
      this.volumeDataset.datasets[0].data.push(x.volume);

      this.pitchDataset.labels.push(x.time?.toString());
      this.pitchDataset.datasets[0].data.push(x.pitch);
    });
  }

  formatDate(date: Date, format: string) {
    return moment(date).format(format);
  }

  showGraphClick(item: GarrySession) {
    this.selectedItem = item;
    this.createDateSets();
    this.showGraph = true;
  }

  removeItemClick(item: GarrySession) {
    this.deleteItem.emit(item);
  }
}
